import React from "react";
import Avatar from "@components/Avatar"
import "./../styles/components/_author.scss"

const Author = ({avatar, nickname, firstname, lastname, biography}) => {
    return (
        <div className="author--container">
            <div className="author--title">Written by</div>
            <div className="author--header">
                <Avatar url={avatar} />
                <div className="author--nick">
                    {!!nickname ?
                        nickname
                        :
                        `${firstname} ${lastname}`
                    }
                </div>
            </div>
            <div className="author--biography">
                {biography}
            </div>
        </div>
    )
}

export default Author
