import React from "react"

function Snackbar(props) {
    return (
        <div>
            {props?.snackbarInfo?.show &&
                <div className={`snackbar ${props.snackbarInfo.type} ${props.snackbarInfo.show ? 'show' : ''}`}>
                    <p>{props.snackbarInfo.message}</p>
                </div>
            }
        </div>
    );
}

export default Snackbar;