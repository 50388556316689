import * as React from "react"
// import { Link } from "gatsby"
import Layout from "@components/layout"
import Breadcrumbs from "@components/Breadcrumbs"
import PostNavigation from "@components/PostNavigation"
import PostCategorys from "@components/posts/PostCategorys"
import RelatedPosts from "@components/RelatedPosts"
import ContactUs from "@components/ContactUs"
// import SidebarTrial from "@components/banners/SidebarTrial"
import PopupDiscount from "@components/popup/PopupDiscount"
import PopupDeadline from "@components/popup/PopupDeadline"
// import PopupFreeTrial from "../components/popup/PopupFreeTrial"
import Avatar from "@components/Avatar"
import Seo from "@components/Seo.js"
import BottomBanner from "@components/BottomBanner"
import Sharing from "@components/Sharing"
import Author from "@components/Author"
import CountdownСounterBanner from "./../components/banners/CountdownСounterBanner"
import CountdownСounterBannerSecond from "./../components/banners/CountdownСounterBannerSecond"
import { StructuredDataHelper } from "@helpers/structuredDataHelper.js"
import Loader from "@components/Loader"
import { lazy, Suspense } from "@uploadcare/client-suspense";

const Writers = lazy(() => import('@components/mainpage/Writers'));

const BlogPost = ({pageContext}) => {
    const structuredData = new StructuredDataHelper(pageContext)
    const post = pageContext
    const itemListElements = [
        {
            position: 1,
            name: 'CustomEssayMeister',
            item: 'https://www.customessaymeister.com/'
        },
        {
            position: 2,
            name: '✔️ Blog',
            item: 'https://www.customessaymeister.com/blog'
        },
        {
            position: 3,
            name: `⭐️⭐️⭐️${post.post_title}`,
            item: `${post.id}`
        }
    ]
    const pageData = {
        title: post.post_meta_title,
        description: `⭐ ${post.post_meta_description}`,
        keywords: `${post.category_group_title} ${post.category_title}`,
        og: {
            image: !!post.cover_image_url && post.cover_image_url ? post.cover_image_url : null,
            type: 'article'
        },
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }
    const steps = [
        {
            name: 'Blog',
            path: '/blog'
        },
        {
            name: post.post_slug.replace(/-/g, ' '),
            path: post.url
        }
    ]

    const writersSliderSetting = {
        responsiveViewDesc: 1,
        responsiveViewTablet: 1,
        responsiveViewTablet2: 1,
        responsiveViewMobile: 1,
        animationDuration: 400,
        autoPlayInterval: 5000,
        animationType: 'fadeout',
        autoPlay: true,
        deskTop: false
    }

    return (
        <>
            <Seo {...pageData} />
            <Layout>
                <ContactUs timer={45000}/>
                <div className="page-blog-post">
                    <div className="blog-post__intro page-intro">
                        <div className="wrapper blog-post__intro-wrapper">
                            <div className="blog-post__intro-content">
                                <Breadcrumbs steps={steps}/>
                            </div>
                        </div>
                    </div>
                    {/* <CountdownСounterBannerSecond /> */}
                    <div className="post__content wrapper">
                        <div className="post__content-wrapper">
                            <h1 className="page-intro__title">{post.post_title}</h1>
                            {/* <p className="blog-post__description">
                                {post.post_excerpt}
                            </p> */}
                            <div className="blog-post__intro-footer">
                                <PostCategorys info={post}/>
                                <div className="blog-post__intro-right">
                                    <div className="blog-post__intro-writer">
                                        <Avatar url={post.avatar_url} />
                                        <span className="blog-post__intro-nickname">{post.nickname}</span>
                                    </div>
                                    <span className="blog-post__intro-date">
                                        {new Date(post.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                    </span>
                                </div>
                            </div>
                            <div className="post-navigation__mobile">
                                {post.table_of_contents.length > 0 && <PostNavigation table={post.table_of_contents}/>}
                            </div>
                            {/* {!!post.cover_image_url && post.cover_image_url !== "0" &&
                                <div
                                    className="blog-post__intro-image"
                                >
                                    <picture className="desc-img">
                                        <source data-srcset={`${post.cover_image_url}?encode=webp`} type="image/webp" />
                                        <img data-src={`${post.cover_image_url}?encode=jpeg`} className="lazyload" alt={post.post_title} width="1100" height="800" />
                                    </picture>
                                </div>
                            } */}

                            <div
                                className="content-block"
                                dangerouslySetInnerHTML={{ __html: post.processed_content }}
                            />
                            {!!post.biography && 
                                <Author
                                    avatar={post.avatar_url}
                                    nickname={post.nickname}
                                    firstname={post.firstname}
                                    lastname={post.lastname}
                                    biography={post.biography}
                                />
                            }                        
                            <Sharing
                                url={`https://www.customessaymeister.com/blog/${post.post_slug}`}
                                title={post.post_title}
                                description={post.post_excerpt}
                                image={post.cover_image_url}
                                tags={["customessaymeister", "writing"]}
                            />
                        </div>
                        <div className="post__content-banners">
                            {post.table_of_contents.length > 0 && <PostNavigation table={post.table_of_contents}/>}
                            {/* <div className="banners-wrapper">
                                <SidebarTrial />
                            </div> */}
                            {/* <Suspense fallback={<Loader/>}>
                                <Writers writersSliderSetting={writersSliderSetting}/>
                            </Suspense> */}
                            <RelatedPosts 
                                showDate posts={post.related}
                                slug={post.post_slug}
                                path="blog"
                                columnTitle={`more in ${post.category_group_title} / ${post.category_title}`}
                            />
                        </div>
                    </div>
                    {/* <CountdownСounterBanner /> */}
                    <BottomBanner keyword={post.post_keyword_singular} />
                    {/* <PopupDiscount timer={45000}/> */}
                    {/* <PopupDeadline timer={25000}/> */}
                    {/* <PopupFreeTrial timer={5000}/> */}
                </div>
            </Layout>
        </>
    )
}
export default BlogPost
