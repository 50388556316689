import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "@components/Loader"
function ReCaptcha(props) {
    function onChange(value) {
        props.sendData(value)
    }
    return (
        <div className='recaptcha'>
            <div className='recaptcha-wrapper'>
                {props.loader &&
                    <div className='recaptcha-loader'><Loader /></div>
                }
                <div className='recaptcha-title'>
                    <p>Confirm you are not a robot</p>
                    <p aria-hidden className='recaptcha-close' onClick={props.close}>X</p>
                </div>
                
                <ReCAPTCHA
                    sitekey={process.env.RECAPTCHA_KEY}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default ReCaptcha;