import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form";
import axios from 'axios';
import ReCaptcha from "@components/ReCaptcha"
import Snackbar from "@components/Snackbar"
import FooterFB from "@components/ImagesComponents/FooterFB"
import FooterInst from "@components/ImagesComponents/FooterInst"
import FooterTwitter from "@components/ImagesComponents/FooterTwitter"
import CheckAnimation from "./ImagesComponents/CheckAnimation";

import { BaseUrlLink} from "../helpers/baseUrl";

function ContactUs(props) {
    useEffect(()=> {
        setTimeout(() => setShowPopup(true), props.timer)
    }, [props.timer])
    const [showReCaptcha, setShowReCaptcha] = useState(false)
    const [ticketData, setTicketData] = useState()
    const [loader, setLoader] = useState(false)
    const [snackbarInfo, setSnackbarInfo] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async data => {
        setTicketData(data)
        setShowReCaptcha(true)
    };
    const sendMessage = async (token) => {
        if (token) {
            setLoader(true)
            try {
                await axios.post(`${BaseUrlLink}/api/support/guest/create-ticket`, {
                    message: ticketData.message,
                    email: ticketData.email,
                    recaptcha: token,
                    type: 'inquiry'
                })
                setShowReCaptcha(false)
                reset()
                setShowForm(false)
            }catch (error) {
                console.log(error);
                setSnackbarInfo({
                    message: error.message,
                    type: 'error',
                    show: true
                })
                setShowReCaptcha(false)
            }finally {
                setTimeout(() => setSnackbarInfo({show: false}), 3000)
                setLoader(false)
            }
        }
    }
    const closeReCaptcha = () => {
        setShowReCaptcha(false)
    }
    return (
        <div>
            {showPopup && 
                <div className='contact-us'>
                    <Snackbar snackbarInfo={snackbarInfo} />
                    <div className="contact-us__wrapper">
                        <div className='contact-us__form-wrapper'>
                            <span aria-hidden className="close-popup" onClick={() => setShowPopup(false)}/>
                            {
                                showForm ?
                                <form onSubmit={handleSubmit(onSubmit)} className='contact-us__form'>
                                <h2 className="contact-us__form-title">Consult our writers now</h2>
                                <div>
                                    <textarea
                                        type="text"
                                        className="contact-us__message"
                                        placeholder="What is your assignment?"
                                        rows="2"
                                        {...register('message', {
                                            required: 'Required',
                                            minLength: {
                                                value: 20,
                                                message: 'Must be at least 20 characters.'
                                            },
                                            maxLength: {
                                                value: 9000,
                                                message: 'Must be less than 9000 characters.'
                                            }
                                        })}
                                    />
                                    <div className='error-message'>
                                        {errors?.message && <p>{errors.message.message}</p>}
                                    </div>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        className="contact-us__message"
                                        placeholder="Your Email"
                                        {...register('email', {
                                            required: 'Required',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Must be a valid e-mail'
                                            }
                                        })}
                                    />
                                    <div className='error-message'>
                                        {errors?.email && <p>{errors.email.message}</p>}
                                    </div>
                                </div>
                                {showReCaptcha && <ReCaptcha sendData={sendMessage} close={closeReCaptcha} loader={loader}/>}
                                <button type="submit" className="button button-primary contact-us__button">proceed</button>
                                <p className="contact-us__form-callback">We will get back to you in 5 minutes</p>

                                <span className="contact-us__form-divide" />

                                <div className="footer-social__wrapper">
                                    <div className="footer-social__img">
                                        <a href="https://www.facebook.com/essaymeister" target="_blank" rel="nofollow noreferrer ">
                                            <FooterFB />
                                        </a>
                                    </div>
                                    <div className="footer-social__img">
                                        <a href="https://www.instagram.com/customessaymeister" target="_blank" rel="nofollow noreferrer ">
                                            <FooterInst />
                                        </a>
                                    </div>
                                    <div className="footer-social__img">
                                        <a href="https://twitter.com/essay_meister" target="_blank" rel="nofollow noreferrer ">
                                            <FooterTwitter />
                                        </a>
                                    </div>
                                </div>
                            </form>
                            :
                            <div className="contact-us__form-success">
                                <CheckAnimation />
                                <div className="popup-success__info">
                                    <h2>Your message has been sent successfully!</h2>
                                    <ul className="intro-list">
                                        <li className="intro-description">We can write your essay per YOUR INSTRUCTIONS</li>
                                        <li className="intro-description">We deliver your essay ON TIME</li>
                                        <li className="intro-description">We pledge 100% SATISFACTION for your essay</li>
                                    </ul>
                                    <div className="landing-intro__actions">
                                        <a href={`${BaseUrlLink}/order/new`} className="button button-primary">Order now</a>
                                        <span aria-hidden className="button button-primary" onClick={() => setShowForm(true)}>Back</span>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="contact-us__preview">
                            <div className='contact-us__title'>
                                <h2>
                                    Need help? Look no further. Let's get started! What is your assignment?
                                </h2>
                            </div>
                            <p className='contact-us__description'>
                                Are you stuck with a difficult essay or term paper? Get totally confidential help from a professional writer now.
                            </p>
                            <div className='contact-us__image'>
                                <img src={require('../images/operator.jpg').default} alt="operator"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ContactUs;