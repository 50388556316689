import * as React from "react"

const Avatar = (props) => {
    return (
        <div>
            {props.url || props.src ?
                <div className="avatar">
                    <img src={props.url || props.src} alt="avatar" width="45" height="45" />
                </div>
                :
                <div className="avatar avatar-default"></div>
            }
        </div>
    )
}

export default Avatar
